import React from 'react'

import "./advert.css"

const Advert = () => {
  return (
    <div className='advert'>
        <div className="advert-container">
        <div className="advert-title">
                <h1>ADVERTISEMENT</h1>
            </div>
            <div className="advert-content">
            
            </div>
        </div>
    </div>
  )
}

export default Advert