import React from 'react'

import "./advert.css"

export const Advert2 = () => {
    return (
        <div className='advert'>
            <div className="advert-container">
            <div className="advert-title">
                    <h1>ADVERTISEMENT</h1>
                </div>
                <div className="advert-content advert-conten2">
                
                </div>
            </div>
        </div>
      )
}
